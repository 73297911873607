var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": _vm.maxWidth ? _vm.maxWidth : "35vw"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _vm.title
            ? _c(
                "v-card-title",
                { staticClass: "justify-center white--text next" },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm.text
            ? _c(
                "v-card-text",
                {
                  staticClass:
                    "font-weight-black text-center px-0 pt-3 pb-1 mx-auto"
                },
                [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
              )
            : _vm._e(),
          _vm.subText
            ? _c(
                "v-card-text",
                {
                  staticClass: "font-weight-black text-center px-0 py-1 mx-auto"
                },
                [_vm._v(_vm._s(_vm.subText))]
              )
            : _vm._e(),
          _vm.subMessage
            ? _c(
                "v-card-text",
                {
                  staticClass: "font-weight-black text-center px-0 py-1 mx-auto"
                },
                [_vm._v(_vm._s(_vm.subMessage))]
              )
            : _vm._e(),
          _vm.multiLineText
            ? _c(
                "v-card",
                { staticClass: "elevation-5 ma-4" },
                _vm._l(_vm.splitMessageLine(_vm.multiLineText), function(
                  text,
                  index
                ) {
                  return _c(
                    "v-card-text",
                    {
                      key: index,
                      class: _vm.isLineBreakStyle(text),
                      attrs: { align: "left" }
                    },
                    [_vm._v("\n        " + _vm._s(text) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.footerNote
            ? _c(
                "v-card-text",
                {
                  staticClass: "font-weight-black text-right pr-2 pb-1 mx-auto"
                },
                [_vm._v("\n      " + _vm._s(_vm.footerNote) + "\n    ")]
              )
            : _vm._e(),
          _vm.warningText
            ? _c(
                "v-card-text",
                { staticClass: "text-center py-0 pt-3 mx-auto" },
                [
                  _c(
                    "v-alert",
                    { attrs: { dense: "", outlined: "", type: "error" } },
                    _vm._l(_vm.splitMessageLine(_vm.warningText), function(
                      text,
                      index
                    ) {
                      return _c(
                        "v-col",
                        { key: index, class: _vm.isLineBreakStyle(text) },
                        [_vm._v(_vm._s(text))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.confirmText
            ? _c(
                "v-card-text",
                { staticClass: "text-center py-0 py-3 mx-auto" },
                _vm._l(_vm.splitMessageLine(_vm.confirmText), function(
                  text,
                  index
                ) {
                  return _c(
                    "v-col",
                    { key: index, class: _vm.isLineBreakStyle(text) },
                    [_vm._v(_vm._s(text))]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            { staticClass: "justify-space-around py-5" },
            [
              _vm.negativeButtonTitle !== null
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "title mr-4",
                        staticStyle: { "font-size": "15px !important" },
                        attrs: {
                          color: "back",
                          width: "30vw",
                          height: "40px",
                          "max-width": "170px",
                          dark: ""
                        },
                        on: { click: _vm.onClickNegativeButton }
                      },
                      [
                        Array.isArray(_vm.negativeButtonTitle)
                          ? [
                              _c(
                                "v-container",
                                { staticClass: "py-0" },
                                _vm._l(_vm.negativeButtonTitle, function(
                                  text,
                                  index
                                ) {
                                  return _c("v-row", { key: index }, [
                                    _c(
                                      "span",
                                      { staticClass: "text-center mx-auto" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(text) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                1
                              )
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.negativeButtonTitle) +
                                  "\n          "
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _vm.positiveButtonTitle !== null
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "title",
                        staticStyle: { "font-size": "15px !important" },
                        attrs: {
                          disabled: _vm.disabled,
                          color: "next",
                          width: "30vw",
                          height: "40px",
                          "max-width": "170px",
                          dark: ""
                        },
                        on: { click: _vm.onClickPositiveButton }
                      },
                      [
                        Array.isArray(_vm.positiveButtonTitle)
                          ? [
                              _c(
                                "v-container",
                                { staticClass: "py-0" },
                                _vm._l(_vm.positiveButtonTitle, function(
                                  text,
                                  index
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: index,
                                      staticClass: "mx-auto text-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-center mx-auto" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(text) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.positiveButtonTitle) +
                                  "\n          "
                              )
                            ]
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }