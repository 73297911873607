<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="maxWidth ? maxWidth : '35vw'"
  >
    <v-card>
      <v-card-title v-if="title" class="justify-center white--text next">{{
        title
      }}</v-card-title>

      <v-card-text
        v-if="text"
        class="font-weight-black text-center px-0 pt-3 pb-1 mx-auto"
      >
        {{ text }}
      </v-card-text>
      <v-card-text
        v-if="subText"
        class="font-weight-black text-center px-0 py-1 mx-auto"
        >{{ subText }}</v-card-text
      >
      <v-card-text
        v-if="subMessage"
        class="font-weight-black text-center px-0 py-1 mx-auto"
        >{{ subMessage }}</v-card-text
      >
      <v-card v-if="multiLineText" class="elevation-5 ma-4">
        <v-card-text
          v-for="(text, index) in splitMessageLine(multiLineText)"
          :key="index"
          :class="isLineBreakStyle(text)"
          align="left"
        >
          {{ text }}
        </v-card-text>
      </v-card>
      <v-card-text
        v-if="footerNote"
        class="font-weight-black text-right pr-2 pb-1 mx-auto"
      >
        {{ footerNote }}
      </v-card-text>
      <!-- 警告文言用テキスト -->
      <v-card-text v-if="warningText" class="text-center py-0 pt-3 mx-auto">
        <v-alert dense outlined type="error">
          <v-col
            v-for="(text, index) in splitMessageLine(warningText)"
            :key="index"
            :class="isLineBreakStyle(text)"
            >{{ text }}</v-col
          >
        </v-alert>
      </v-card-text>
      <!-- 確認文言用テキスト（CompletedDialogのレイアウトと合わせるために使う） -->
      <v-card-text v-if="confirmText" class="text-center py-0 py-3 mx-auto">
        <v-col
          v-for="(text, index) in splitMessageLine(confirmText)"
          :key="index"
          :class="isLineBreakStyle(text)"
          >{{ text }}</v-col
        >
      </v-card-text>
      <v-card-actions class="justify-space-around py-5">
        <template v-if="negativeButtonTitle !== null">
          <v-btn
            class="title mr-4"
            color="back"
            width="30vw"
            height="40px"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
          >
            <template v-if="Array.isArray(negativeButtonTitle)">
              <v-container class="py-0">
                <v-row
                  v-for="(text, index) in negativeButtonTitle"
                  :key="index"
                >
                  <span class="text-center mx-auto">
                    {{ text }}
                  </span>
                </v-row>
              </v-container>
            </template>
            <template v-else>
              {{ negativeButtonTitle }}
            </template>
          </v-btn>
        </template>
        <template v-if="positiveButtonTitle !== null">
          <v-btn
            :disabled="disabled"
            class="title"
            color="next"
            width="30vw"
            height="40px"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickPositiveButton"
          >
            <template v-if="Array.isArray(positiveButtonTitle)">
              <v-container class="py-0">
                <v-row
                  v-for="(text, index) in positiveButtonTitle"
                  :key="index"
                  class="mx-auto text-center"
                >
                  <span class="text-center mx-auto">
                    {{ text }}
                  </span>
                </v-row>
              </v-container>
            </template>
            <template v-else>
              {{ positiveButtonTitle }}
            </template>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'Dialog',
  props: {
    maxWidth: Number,
    title: String,
    text: String,
    subText: String,
    subMessage: String,
    multiLineText: String,
    footerNote: String,
    warningText: String,
    confirmText: String,
    negativeButtonTitle: [String, Array],
    positiveButtonTitle: [String, Array],
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      breakLineStyle: ['ma-0', 'px-2', 'py-3'],
      noBreakLineStyle: ['ma-0', 'px-2', 'py-0'],
    };
  },
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    isLineBreakStyle(message) {
      return message ? this.noBreakLineStyle : this.breakLineStyle;
    },
  },
};
</script>
<style>
.col {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
}
</style>
